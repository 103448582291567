import React, { useRef, useLayoutEffect } from 'react'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Text } from '@/components/Text'
import { Wrapper, StyledDivider } from './styled'
import { MobileFooter } from '@/layouts/styled'
import MobileBack from '@/components/MobileBack'
import gsap from 'gsap'
import { Innovation } from '@/components/Join'

const PlanetPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Page
        content={
          <PageWrapper>
            <Wrapper>
              <Innovation />
            </Wrapper>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
          isSubPage: true,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          variant="light"
          delay={0}
          time={1}
          url="/our-dna/join"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default PlanetPage
